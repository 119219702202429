
import { defineComponent, onMounted, computed } from "vue";
import ClientListTable from "@/components/underwriters/ClientListTable.vue";
import DashboardClaimSummary from "@/components/underwriters/DashboardClaimSummary.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import store from "@/store";

export default defineComponent({
  name: "underwriter-dashboard",
  components: {
    ClientListTable,
    DashboardClaimSummary,
  },
  data() {
    return {
      summary: {
        numberOfClients: 0 as number,
        outstandingClaims: 0 as number,
        settledClaims: 0 as number,
        totalClaims: 0 as number,
      },
      clients: [],
    };
  },
  setup() {
    const user = computed(() => {
      return store.getters.currentUser;
    });

    console.log(user.value);
    // const underwriterName =
    //   user.value["underwriter"] != ""
    //     ? user.value["underwriter"]["name"] + " Dashboard"
    //     : "Dashboard";

    // onMounted(() => {
    //   setCurrentPageTitle(underwriterName);
    // });

    return { user };
  },
  created() {
    this.getDashboardSummary();

    //Set page title
    document.title = "Underwriter Dashboard | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getDashboardSummary() {
      //Fetch underwriter user dashboard data
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.UNDERWRITERS_DASHBOARD_ROUTE)
          .then(({ data }) => {
            //Assign data to props
            this.summary.numberOfClients = data.data.numberOfClients;
            this.summary.outstandingClaims = data.data.outstandingClaims;
            this.summary.settledClaims = data.data.settledClaims;
            this.summary.totalClaims = data.data.totalClaims;
            this.clients = data.data.clients;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
